import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import AppContainer from './app_container'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <AppContainer />,
    document.querySelector("#js-app"),
  )
});
